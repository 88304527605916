import React from "react";
import SmallJewellery from "./smallJewellery/print";
import NewSmallJewellery from "./smallJewellery/newPrint";
import NewSmallGemPrint from "./smallGemstone/newPrint";
import SmallGemPrint from "./smallGemstone/print";
import SmallDiaPrint from "./smallDiamond/print";
import SmallGemJewPrint from "./smallGemstoneJewellery/print";
import NewSmallDiaPrint from "./smallDiamond/newPrint";
import NewSmallGemJewPrint from "./smallGemstoneJewellery/newPrint";
function Certificatemain(props) {
  return (
    <div>
      {props.data && props.data.length
        ? props.data.map((certificateData) =>
            certificateData.report_id === "3" ? (
              props.newPrint ? (
                <NewSmallJewellery data={[certificateData]} />
              ) : (
                <SmallJewellery data={[certificateData]} hidePrintBtn={true}/>
              )
            ) : certificateData.report_id === "4" ? (
              props.newPrint ? (
                <NewSmallGemPrint data={[certificateData]} />
              ) : (
                <SmallGemPrint data={[certificateData]} hidePrintBtn={true}/>
              )
            ) : certificateData.report_id === "5" ? (
              props.newPrint ? (
                <NewSmallDiaPrint data={[certificateData]} />
              ) : (
                <SmallDiaPrint data={[certificateData]} hidePrintBtn={true}/>
              )
            ) : certificateData.report_id === "6" ? (
              props.newPrint ? (
                <NewSmallGemJewPrint data={[certificateData]} />
              ) : (
                <SmallGemJewPrint data={[certificateData]} hidePrintBtn={true}/>
              )
            ) : null
          )
        : null}
        <a
        className="printBtnCert"
        href="javascript:window.print()"
        style={{
          backgroundColor: "#4f81bc",
          padding: "10px 20px",
          color: "#fff",
          fontFamily: "arial",
          float: "right",
          marginRight: "20px",
          textDecoration: "none",
          border: "0px solid #385d89",
        }}
        id="printbtn"
      >
        Print
      </a>
    </div>
  );
}

export default Certificatemain;
