import axios from "axios";
import React, { useEffect, useState } from "react";
import JewelleryReport from "./JewelleyReport/print";
import "./diamond.scss";
import "./print.scss";
import "./index.css";
import GemStoneCertificate from "./GemReport/certificate/print";
import DiamondCertificate from "./diamondGrading/certificate/print";
import SmallJewellery from "./smallJewellery/print";
import NewSmallJewellery from "./smallJewellery/newPrint";
import NewSmallGemPrint from "./smallGemstone/newPrint";
import SmallGemPrint from "./smallGemstone/print";
import SmallDiaPrint from "./smallDiamond/print";
import SmallGemJewPrint from "./smallGemstoneJewellery/print";
import MasterColoredPrint from "./masterColored/print";
import AppraisalPrint from "./appraisalCertificate/print";
import logo from "./../Images/Logo 2.png";
import NewSmallDiaPrint from "./smallDiamond/newPrint";
import NewSmallGemJewPrint from "./smallGemstoneJewellery/newPrint";
import Certificatemain from "./certificatemain";
function Certificate() {
  const url = new URL(window.location);
  const arr = url.searchParams.get("entries");
  const newPrint = url.searchParams.get("newPrint");
  console.log(arr);
  const [certificateData, setCertificateData] = useState([]);
  const [certificateLoader, setCertificateLoader] = useState(false);
  useEffect(() => {
    setCertificateLoader(true);
    setCertificateData([]);
    var config = {
      method: "get",
      url: `https://backendggi.ggilab.com/getMultipleReportsWeb/?searchText=${arr}`,
      headers: {},
    };

    axios(config)
      .then(function (response) {
        // console.log(response.data);
        // dispatch(getCertificateSuccess(response.data));
        setCertificateLoader(false);
        setCertificateData(response.data);
      })
      .catch(function (error) {
        setCertificateLoader(false);
        // setCertificateData([]);
        console.log(error);
      });
  }, []);
  console.log(newPrint);
  return (
    <div>
      {certificateLoader ? (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <div className="loaderDiv">
            <div>
              <img alt="logo" src={logo} />
              <h1>GALAXY GEMOLOGICAL INSTITUTE</h1>
            </div>
          </div>
        </div>
      ) : certificateData.length ? (
        certificateData[0].reportContent ? (
          <JewelleryReport data={certificateData} />
        ) : certificateData[0].report_id === "2" ? (
          <GemStoneCertificate data={certificateData} />
        ) : certificateData[0].report_id === "1" ? (
          <DiamondCertificate data={certificateData} />
        ) : certificateData[0].report_id === "7" ? (
          <MasterColoredPrint data={certificateData} />
        ) : certificateData[0].report_id === "3" ||
          certificateData[0].report_id === "4" ||
          certificateData[0].report_id === "5" ||
          certificateData[0].report_id === "6" ? (
          <Certificatemain data={certificateData} newPrint={newPrint} />
        ) : (
          <AppraisalPrint data={certificateData} />
        )
      ) : (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <h2>This certificate is not exist</h2>
        </div>
      )}

      {/* <AppraisalPrint data={certificateData} /> */}
      {/* {certificateData ? (
                certificateData.length ? (

                ): 
            )
            ) : (
           
            )} */}
    </div>
  );
}

export default Certificate;
