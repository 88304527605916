import logo from './logo.svg';
import './App.css';
import Certificate from './managerPanel/Certificate';

function App() {
  return (
    <div className="App">
      <div className="marqueeDiv">
        <marquee>
          <div className="newsDIv">
            <div className="liveIconDiv">
              <div className="liveIcon"></div>
              </div>
              Note:  If you're unable to locate your certificate, please reach out to us for assistance. You can contact us at the following numbers: Karachi: <a href="tel:+92 (21) 3521-6044"> +92 (21) 3521-6044</a>, Peshawar: <a href="+92 (91) 256-8456"> +92 (91) 256-8456</a>.

          </div>
        </marquee>
      </div>
      <Certificate />
    </div>
  );
}

export default App;
