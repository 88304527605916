export const dateFunction = (a, b) => {
    if (a !== "0000-00-00") {

        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        const d = new Date(a);
        let month = monthNames[d.getMonth()]
        let date = d.getDate()
        let year = d.getFullYear()

        return `${date} ${month} ${year}`
    } else {

        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        const d = new Date(b);
        let month = monthNames[d.getMonth()]
        let date = d.getDate()
        let year = d.getFullYear()

        return `${date} ${month} ${year}`
    }
}